@use "../../styles/colors";
@use "../../styles/sizes";
@use "../../styles/mixins";

.heroSection {
    max-width: sizes.$md;
    padding: 20px 10px;
    margin-left: auto;
    margin-right: auto;
}

.heroContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.headshot {
    border: 3px solid colors.$border;
    border-radius: 6px;
}

.textHeader {
    color: colors.$text;
    text-align: center;
    margin-bottom: 5px;
    font-size: 2rem;
}

.text {
    color: colors.$text;
    text-align: left;
    font-size: 1.05rem;
    padding-bottom: 15px;
    .highlightText {
        font-weight: 700;
        color: colors.$accent-light;
        font-size: 1.1rem;
    }
}

.iconsContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: sizes.$md;
}

.iconContainer {
    cursor: pointer;
    text-decoration: none;
}

.icon {
    padding: 10px;
    width: 50px;
    height: 50px;
    color: colors.$icons;
    &:hover {
        color: colors.$accent;
        transition: color 200ms ease-out;
    }
}

@media only screen and (min-width: sizes.$md) {
    .textHeader {
        font-size: 2.15rem;
    }

    .text {
        font-size: 1.15rem;
        .highlightText {
            font-size: 1.2rem;
        }
    }

    .headshot {
        height: 100%;
        width: 330px;
    }

    .icon {
        width: 60px;
        height: 60px;
    }
}

@media only screen and (min-width: sizes.$lg) {
    .heroSection {
        margin-top: 100px;
        max-width: sizes.$lg;
    }

    .heroContent {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        margin-bottom: 40px;
    }
    
    .headshot {
        height: 100%;
        width: 380px;
    }
    
    .textHeader {
        text-align: left;
        font-size: 2.3rem;
    }

    .text {
        font-size: 1.2rem;
        .highlightText {
            font-size: 1.25rem;
        }
    }

    .icon {
        width: 70px;
        height: 70px;
    }
}