// write global styles here
// mixin will handle global classes
// this is to select elements globally

@use "./mixins";

.app {
    height: 100vh;
    width: 100%;
}

main {
    flex: 1 1 auto;
    @include mixins.container();
}