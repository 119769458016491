@use "../../styles/colors";
@use "../../styles/sizes";
@use "../../styles/mixins";

.footer {
    width: 100%;
}

.footerBrand {
    color: colors.$accent;
    padding: 15px;
}